<template>
     <footer>
        <div class="bg-2 col-lg-12 py-1">
            <div class="fl-te-c mx-9 font-weight-400">
                <p class="mb-lg-0 fs--2 text-2">&copy; {{ new Date().getFullYear() }}</p>
                <p class="pt-2 text-2">All Rights Reserved.</p>
            </div>
        </div>
        </footer>
</template>

<script>
export default {
    name: 'mainfooter'
};
</script>

<style scoped>

</style>
