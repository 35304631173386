<template>
    <div class="" id="top">
        <notifications/>
        <div class="fl-x-cc bg-primary pos-ft z-index-max">
            <lego-nav-bar @link-event="linkEvent" li-classes="text-fs-0 px-0" drop-down-animation="fade" drop-down-animation-mobile="fade" hover-effect="" :colored="true"
                          :items="menu" shadow="0"
                          class="">
                <template #logo>
                    <router-link to="/" class="fl-x-cc">
                        <img src="../assets/img/logo/logo-img.png" alt="">
                    </router-link>
                </template>
            </lego-nav-bar>
        </div>
        <div class="pt-7 pb-3 bg-1 min-h-95vh">
            <transition name="fade" mode="out-in">
                <router-view>

                </router-view>
            </transition>
        </div>
        <!--        <footer class="bg-2">-->
        <!--            <div class="col-12">-->
        <!--                <div class="fl-te-b mx-5">-->
        <!--                    <p class="mb-lg-0 fs&#45;&#45;2">&copy; {{ new Date().getFullYear() }}-->
        <!--                    </p>-->
        <!--                    <p>All Rights Reserved.</p>-->
        <!--                </div>-->
        <!--            </div>-->
        <!--        </footer>-->
        <mainfooter></mainfooter>
    </div>
</template>

<script>
import LegoNavBar from 'lego-framework/src/components/NavBar';
import getMenu from '../data/menu';
// import TransactionAndLogoutHeader from '../views/common-components/TransactionAndLogoutHeader';
import Mainfooter from '../views/mainfooter';
import urls from '../data/urls';
import axios from 'secure-axios';
import store from '@/store';

export default {
    components: {
        // TransactionAndLogoutHeader,
        Mainfooter,
        LegoNavBar
    },
    data () {
        return {
            menu: getMenu(),
            value: '',
            logoutUrl: urls.auth.logout,
            brandsExcelDownloadUrl: urls.drugs.brandExcel.excel,
            excelDownloadLink: ''
        };
    },
    metaInfo: {
        titleTemplate: '%s - Project Name',
        htmlAttrs: {
            lang: 'en',
            amp: true
        }
    },
    name: 'MainLayout',
    beforeMount () {
        // this.loadDetails();
    },
    methods: {
        async linkEvent (name, event) {
            const that = this;
            if (name === 'logout') {
                // alert('logout');
                const response = await axios.post(that.logoutUrl)
                    .catch(function (exception) {
                        that.serverError();
                    });
                console.log('resp', response.data.success);
                if (response.data.success) {
                    this.$router.push('/login');
                }
            }
            if (name === 'brand-excel-download') {
                // console.log('event', event.target);
                // event.target.download = true;

                // event.target.download = true;
                // event.target.setAttribute('download', fileUrl);
                // event.target.href = baseUrl + fileUrl;
                // this.excelDownloadLink = baseUrl + fileUrl;
                // event.target.click();
                // this.loadDetails();
                const that = this;
                const response = await axios.get(that.brandsExcelDownloadUrl)
                    .catch(function (exception) {
                        that.serverError();
                    });
                console.log('resp', response.data.success);
                const fileUrl = await response.data.url;
                const baseUrl = process.env.VUE_APP_BASE_URL;
                this.excelDownloadLink = baseUrl + fileUrl;
                window.open(this.excelDownloadLink);
            }
        },
        async loadDetails () {
            const that = this;
            const response = await axios.get(that.brandsExcelDownloadUrl)
                .catch(function (exception) {
                    that.serverError();
                });
            console.log('resp', response.data.success);
            const fileUrl = await response.data.url;
            const baseUrl = process.env.VUE_APP_BASE_URL;
            this.excelDownloadLink = baseUrl + fileUrl;
        },
        setRedirect () {
            console.log(store.getters.currentUser);
            if (store.getters.currentUser.admin === true) {

            }
            return store.getters.currentUser.admin === true ? '/dashboard/' : '/animal-page/';
        }
    }
};
</script>

<style>
    .fade-enter-active, .fade-leave-active {
        transition: opacity .5s;
    }

    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
    {
        opacity: 0;
    }
</style>
